import { urlFor } from '../../client';

import './ImageComponent.scss';

const ImageComponent = ({ value, isInline }) => {
  const { description, imgUrl, source, fit, diagram } = value
  // const { width, height } = getImageDimensions(value)
  return (
    <>
      <figure className={`${isInline ? 'fig-inline' : 'fig-block'} ${diagram ? 'diagram' : ''}`}>
        <img src={urlFor(imgUrl)} alt="" style={{ objectFit: fit ? 'contain' : 'cover' }} />
        <div>
          {source && <p>{`Source: ${source}`}</p>}
        </div>
      </figure>
      {!isInline && <div className='post-figcaption'>
        <p>{description}</p>
      </div>}
    </>
  )
}

export default ImageComponent;
