import React, { useState, useEffect } from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { PortableText } from '@portabletext/react';
import { PortableComponents } from '../../components';

import './PostContent.scss';

const PostContent = () => {
    const { title } = useParams()
    const [works, setWorks] = useState([]);

    useEffect(() => {
        const query = `*[_type == "works" && title == '${title}']`

        client.fetch(query).then((data) => {
            setWorks(data[0]);
        });

    }, [title])
    return (
        <div className="app__post-page">
            <h1>{works.title}</h1>
            {works.date ? <time>{format(new Date(works.date), "yyyy-MM-dd HH:mm")}</time> : <></>}
            <div className="image">
                {works.imgUrl ?
                    <img src={urlFor(works.imgUrl)} alt="" />
                    :
                    <>
                    </>
                }
            </div>
            <PortableText value={works.content} components={PortableComponents} />
        </div>
    )
}

export default AppWrap(
    MotionWrap(PostContent, 'app__post-content'),
     'post-content','app__whitebg');