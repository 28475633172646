import FormulaComponent from '../FormulaComponent/FormulaComponent';
import CodeComponent from '../CodeComponent/CodeComponent';
import ImageComponent from '../ImageComponent/ImageComponent';
import QuoteComponent from '../QuoteComponent/QuoteComponent';

import './PortableComponents.scss'

// const FormulaComponent = ({ children }) => {
//   return (
//     <KaTex texExpression={children} />
//   )
// }

const BlockComponent = {
  h2: ({ children }) => <h2 className='post-h2'>{children}</h2>,
  h4: ({ children }) => <h4 className='post-h4'>{children}</h4>,
  h5: ({ children }) => <h5 className='post-h5'>{children}</h5>,
  normal: ({ children }) => <p className='post-p'>{children}</p>,
  indented: ({ children }) => <p style={{ marginLeft: "1em" }}>{children}</p>
}

const ListComponent = {
  number: ({ children }) => <ol className='post-ol'>{children}</ol>,
  bullet: ({ children }) => <ul className='post-ul'>{children}</ul>,
}

const ListItemComponent = {
  number: ({ children }) => <li className='post-li'>{children}</li>,
  bullet: ({ children }) => <li className='post-li'>{children}</li>,
}

const PortableComponents = {
  types: {
    figure: ImageComponent,
    figureInline: ImageComponent,
    code: CodeComponent,
    quote: QuoteComponent,
    formula: FormulaComponent
  },
  block: BlockComponent,
  list: ListComponent,
  listItem: ListItemComponent
}

export default PortableComponents;