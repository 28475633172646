import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as WorkIcon } from '../../assets/work.svg';
import { ReactComponent as SchoolIcon } from '../../assets/school.svg';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Experience.scss';
import { images } from '../../constants';

const Experience = () => {
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const query = '*[_type == "experiences"] | order(order desc)';

        client.fetch(query).then((data) => {
            setExperiences(data)
        });
    }, []);

    let workIconStyles = { background: "#06D6A0" }
    let schoolIconStyles = { background: "#f9c74f" }

    return (
        <>
            <h2 className="head-text"><span>The Story</span> So Far</h2>

            <VerticalTimeline>
                {
                    experiences.map((experience) => {
                        let isWorkIcon = experience.icon === "work";
                        return (
                            <VerticalTimelineElement
                                key={experience._id}
                                date={experience.date}
                                dateClassName="date"
                                iconStyle={isWorkIcon ? workIconStyles : schoolIconStyles}
                                icon={isWorkIcon ? <WorkIcon /> : <SchoolIcon />}
                            >
                                <h2 className='verticle-timeline-element-title'>{experience.company}</h2>
                                <h3 className='verticle-timeline-element-title'>{experience.title}</h3>
                                <h5 className='vertical-timeline-element-subtitle'>{experience.location}</h5>
                                <motion.div
                                    key={experience._id}
                                    transition={{ duration: 0.5, delayChildren: 0.5 }}
                                    className="app__exp-works">
                                    {experience.icon === 'work' ?
                                        <>
                                            {experience?.workex?.map((client) => (
                                                <div className="app__exp-item app__flex" key={client._id}>
                                                    <div className="app__exp-content app__flex">
                                                        <h6 className="bold-text">{client.name}</h6>
                                                        {client?.impact?.map((project) => (
                                                            <div className='app__exp-card app__flex' key={project._id}>
                                                                <div className="app__exp-card-snippet app__flex">
                                                                    <div className="app__exp-card-img app__flex">
                                                                        <img src={urlFor(project.name_img)} alt={project.name} />
                                                                    </div>
                                                                    <div className="app__exp-card-item app__flex">
                                                                        <p className="p-text" >{project.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='app__exp-card-flow'>
                                                                    <img className='down' src={images.down} alt="down" />
                                                                    <img className='icon' src={images.plus} alt="plus" />
                                                                </div>
                                                                <div className="app__exp-card-snippet app__flex" >
                                                                    <div className="app__exp-card-img app__flex">
                                                                        <img src={urlFor(project.technique_img)} alt={project.technique} />
                                                                    </div>
                                                                    <div className="app__exp-card-item app__flex">
                                                                        <p className="p-text">{project.technique}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='app__exp-card-flow'>
                                                                    <img className='down' src={images.down} alt="down" />
                                                                    <img className='icon' src={images.impact} alt="impact" />
                                                                </div>
                                                                <div className="app__exp-card-snippet app__flex" >
                                                                    <div className="app__exp-card-img app__flex">
                                                                        <img src={urlFor(project.impact_img)} alt={project.impact} />
                                                                    </div>
                                                                    <div className="app__exp-card-item app__flex">
                                                                        <p className="p-text">{project.impact}</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        ))}

                                                    </div>
                                                </div>
                                            ))}
                                        </> :
                                        <>
                                            {experience?.workex?.map((client) => (
                                                <div className="app__exp-item app__flex" key={client._id}>
                                                    <div className="app__exp-content app__flex">
                                                        <h6 className="bold-text">{client.name}</h6>
                                                        {client?.impact?.map((project) => (
                                                            <div className='app__exp-school app__flex' key={project._id}>
                                                                    <div className="app__exp-school-item app__flex">
                                                                        {/* <p className="p-text" >{project.name}</p> */}
                                                                        {
                                                                            project.name.split(',').map((item) => (
                                                                                <div className="app__exp-school-item-elem">
                                                                                    <p className="p-text">{item}</p>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </>}
                                </motion.div>
                            </VerticalTimelineElement>
                        )
                    })
                }
            </VerticalTimeline>
        </>
    )
}

export default AppWrap(
    MotionWrap(Experience, 'app__experience'),
    'experience', 'app__primarybg');