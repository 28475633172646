import React, { useRef, useEffect } from 'react';
import katex from 'katex';
import 'katex/dist/katex.min.css'


import './FormulaComponent.scss'

const FormulaComponent = ({value, isInline}) => {
    const texExpression = value.latex;
    const containerRef = useRef();

    useEffect(() => {
        katex.render(texExpression, containerRef.current);
    }, [texExpression]);

    return (
           <div className={isInline? 'katex-inline' : 'katex-block'} ref={containerRef} />
    )
}

export default FormulaComponent;