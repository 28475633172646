import Refractor from 'react-refractor';
import jsx from 'refractor/lang/jsx'
import js from 'refractor/lang/javascript';
import py from 'refractor/lang/python';
import r from 'refractor/lang/r';
import sql from 'refractor/lang/sql';

import './CodeComponent.scss';

Refractor.registerLanguage(js);
Refractor.registerLanguage(py);
Refractor.registerLanguage(r);
Refractor.registerLanguage(sql);
Refractor.registerLanguage(jsx);

const CodeComponent = ({ value }) => {
    return (
      <div>
        <p>{value.filename}</p>
        <Refractor
          language={value.language}
          value={value.code}
        />
      </div>
    )
  }

export default CodeComponent;