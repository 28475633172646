import React from 'react';
import { NavigationDots, SocialMedia } from '../components';
import { useParams } from 'react-router-dom';

const AppWrap = (Component, idName, classNames) => function HOC() {
    const { title } = useParams()
    return (
        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia />

            <div className='app__wrapper app__flex'>
                <Component />

                <div className="copyright">
                    <p className="p-text" style={{margin: '0px'}}>@2022 Pranav Alva</p>
                    <p className="p-text" style={{margin: '0px'}}>All rights reserved</p>
                </div>
            </div>
            <NavigationDots active={idName} isMain={!title}/>
        </div>
    )
}

export default AppWrap;