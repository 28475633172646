import React from 'react';

import './QuoteComponent.scss';

const QuoteComponent = ({ value }) => {
    const { text, author, url, resource } = value
    return (
            <blockquote cite={url}>
            {text} <span>&#8212;</span> {author}<em>{resource? ", " + resource : ""}</em>
            </blockquote>
    )
}

export default QuoteComponent;