import React from 'react';
import { BsTwitter, BsInstagram, } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => {
    return (
        <div className='app__social'>
            <div>
                <a href='https://twitter.com/AlvaPranav'>
                <BsTwitter />
                </a>
            </div>
            <div>
                <a href='https://www.instagram.com/pranavalva/'>
                <BsInstagram />
                </a>
            </div>
            <div>
                <a href='https://www.facebook.com/pranav.alva.3'>
                <FaFacebookF />
                </a>
            </div>
        </div>
    )
}

export default SocialMedia;