import React from 'react';
import { About, Footer, Header, Skills, Testimonial, Work, Experience } from '../../container';
import { Navbar } from '../../components';

const Main = () => {
    return (
        <div className='app'>
            <Navbar />
            <Header />
            <About />
            <Work />
            <Skills />
            <Experience />
            <Testimonial />
            <Footer />
        </div>
    );
}

export default Main;