import React from 'react';

const NavigationDots = ({ active, isMain }) => {
    return (
        <>
        {isMain && (
        < div className='app__navigation' >
            {
                ['home', 'about', 'work', 'skills', 'experience', 'testimonials', 'contact'].map((item, index) => (
                    <a
                        href={`#${item}`}
                        key={item + index}
                        className='app__navigation-dot'
                        style={active && (active === item) ? { backgroundColor: '#313BAC' } : { }}
                    >
                    </a>
                ))
            }
        </div >
        )}
        {!isMain && (
        < div className='app__navigation' >
            {
                ['home', 'about', 'work', 'skills', 'experience', 'testimonials', 'contact'].map((item, index) => (
                    <a
                        href={`#${item}`}
                        key={item + index}
                        className='app__navigation-dot'
                        style={{backgroundColor: '#ffff'}}
                    >
                    </a>
                ))
            }
        </div >
        )}
        </>
    )
}

export default NavigationDots