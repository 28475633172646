import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Main, Post } from './pages'
import './App.scss';


const App = () => {
    return (
        <Routes>
            <Route index element={<Main />} />
            <Route path={'/works/:title'} element={<Post />} />
        </Routes>
    );
}


export default App;
