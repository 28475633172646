import React from 'react';

import { Navbar } from '../../components';
import { PostContent } from '../../container';

const Post = () => {
    return (
        <>
        <Navbar />
        <PostContent />
        </>
    )
}

export default Post;